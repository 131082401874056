"use strict";

// NAVIGATION
$('.fusionovation-labs-header__hamburger').on('click', function (e) {
  e.preventDefault();

  if (!$('.fusionovation-labs-header__hamburger').hasClass('is-active')) {
    showNavigation();
  } else {
    hideNavigation();
  }
});
$('.fusionovation-labs-header__navigation').on('click', function (e) {
  e.preventDefault();
  hideNavigation();
}); // NAV SMOOTH SCROLLING

$('.fusionovation-labs header a[href*="#"]') // Remove links that don't actually link to anything
.not('[href="#"]').not('[href="#0"]').click(function (event) {
  // On-page links
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']'); // Does a scroll target exist?

    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      var pageOffset = target.offset().top - 80;

      if ($(window).width() >= 900) {
        pageOffset = target.offset().top - 112;
      }

      $('html, body').animate({
        scrollTop: pageOffset
      }, 1000);
    }
  }
}); // PARTICIPANTS

if ($(window).width() < 900) {
  $('#participants-slider').carousel();
  $('#participants-slider').addClass('carousel-active');
}

$(window).on('resize', function (e) {
  if ($(window).width() < 900 && !$('#participants-slider').hasClass('carousel-active')) {
    $('#participants-slider').carousel();
    $('#participants-slider').addClass('carousel-active');
  } else if ($(window).width() >= 900 && $('#participants-slider').hasClass('carousel-active')) {
    $('#participants-slider').carousel('dispose');
    $('#participants-slider').removeClass('carousel-active');
  }
});

function showNavigation() {
  $('header').addClass('is-active');
  $('.fusionovation-labs-header__hamburger').addClass('is-active');
  $('header nav').addClass('is-active');
  $('html').css("overflow", "hidden");
}

function hideNavigation() {
  $('header').removeClass('is-active');
  $('.fusionovation-labs-header__hamburger').removeClass('is-active');
  $('header nav').removeClass('is-active');
  $('html').removeAttr('style');
}